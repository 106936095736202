@font-face {
  font-family: 'SB Sans Display';
  src: url('./assets/sb-sans-display.otf') format('otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/Inter/Inter-V.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Arial';
  src: url('./assets/Arial.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SB Sans Text';
  src: url('./assets/sb-sans-text-medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/Roboto.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
html {
  min-height: 100%;
  height: 100%;
  background: linear-gradient(91.05deg, #00D1FF -0.03%, #661AFF 100.08%);

}
html,
body {
  height: 100%;
  margin: 0;
}
#root {
  height: 100%;
}
body {
  margin: 0;
  /* padding-bottom: 60px; */
  background: transparent;
}

.ant-form-item-explain-error {
  font-size: 14px; 
  top: 30px;
  position: absolute;
  left: 0;
  text-align: left;
}

.ant-input-group-addon {
  display: none !important;
}

.ant-otp-input {
  width: 44px;
  height: 44px;
  gap: 0px;
  border-radius: 8px;
  border: 1px solid rgba(192, 192, 192, 1);
  background: rgba(246, 246, 246, 1);
}

.ant-input-outlined:focus-within {
  border-color: rgba(55, 112, 255, 1) !important;
  box-shadow: none !important;
}
.ant-input-outlined:hover {
  border-color: rgba(55, 112, 255, 1) !important;
  box-shadow: none !important;

}

.ant-upload-wrapper {
  width: 100%;
}

.ant-upload-drag:hover {
  border: 1px dashed rgba(85, 85, 85, 1);
  border-color: rgba(85, 85, 85, 1) !important;
  background: rgba(0, 0, 0, 0.07);
}

.ant-card-head {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
  min-height: auto !important;
  margin-bottom: -1px !important;
  text-align: left !important;
  padding: 0!important;
  background: transparent !important;
  border-bottom: none!important;
  font-family: 'Inter' !important;
  font-size: 26.49px !important;
  font-weight: 500 !important;
  line-height: 32px !important;
  letter-spacing: -0.2669999897480011px !important;
  color: rgba(22, 22, 22, 1) !important;
  border-radius:0 !important;
}


.ant-card-body {
  padding: 0 !important;
}

.ant-table-thead {
  border-bottom: 1px solid rgba(210, 210, 210, 1);
  padding-bottom: 11px; 
  background: white;  
}
.ant-table-thead > tr > th {
  color: rgba(128, 128, 128, 1);
  font-family: 'Arial';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}


.ant-select-single {
  height: auto !important;
}
.custom-select .ant-select-selector {
  padding: 16px !important; 
  border-radius: 8px; 
  border: 1px solid rgba(210, 210, 210, 1) !important;
  font-family: 'Arial' !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  text-align: left !important;
  color: rgba(32, 32, 32, 1) !important;
  outline: none !important;
  box-shadow: none !important;
}


@media screen and (max-width: 500px) {
  .custom-select .ant-select-selector {
    padding: 8px !important; 
    border-radius: 8px; 
    border: 1px solid rgba(210, 210, 210, 1) !important;
    font-family: 'Arial' !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: left !important;
    color: rgba(32, 32, 32, 1) !important;
    outline: none !important;
    box-shadow: none !important;
  }

  .custom-select .ant-select-arrow {
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 7px solid black;
    right: 16px;
    top: 53%;
  }

  .ant-card-head-title {
    display: flex !important;
  }
  
}

.custom-select .ant-select-suffix {
  display: none;
}

.ant-select-dropdown {
  border-radius: 8px !important; 
  background-color: white  !important; 
  border: 1px solid rgba(242, 243, 245, 1)  !important;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04)  !important;
  padding: 0 !important;
}

.ant-select-item-option {
  padding: 12px !important; 
  color: rgba(14, 14, 14, 1) !important; 
  font-family: 'Inter' !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  text-align: left !important;
  background: white !important;
}
.ant-select-item-option-selected {
  color: rgba(55, 112, 255, 1) !important;
}
.ant-select-item-option-selected:hover {
  background: white !important;
}

.ant-select-item-option:hover {
  background: rgba(0, 0, 0, 0.07) !important;
}
.ant-upload-list {
  display: none !important;
}
.choice__item {
  width: 100%;
  background: rgba(255, 255, 255, 1);
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 12px;
  cursor: pointer;
  padding: 12px;
  height: 48px;
}

.choice__item:hover {
  background: rgba(0, 0, 0, 0.07);
}
.prompt-button {
  background: rgba(55, 112, 255, 1);
  display: flex;
  gap: 8px;
  align-items:center;
  border-radius: 80px;
  padding: 12px 16px;
  cursor: pointer;
  border: none;
  outline: none;
}

@media screen and (max-width: 500px) {
  .prompt-button {
    padding: 8px 12px;
    gap: 4px;

  }
}
.prompt-button:hover {
  background: rgba(94, 140, 255, 1);

}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
.popup {
  width: 500px;
  height: 460px;
  border-radius: 12px;
  background: white;
  position: relative;
  padding: 28px 28px 40px 28px;

}

.conic-gradient-donut-loader {
  display: inline-block;
  width: 24px;
  min-width: 24px;
  min-height: 24px;
  height: 24px;
  position: relative;
}

.circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: conic-gradient(from 180deg at 50% 50%, #00D1FF 0deg, rgba(102, 26, 255, 0) 360deg); /* Ваш градиент */
  position: relative;
  animation: spin 1.5s linear infinite; 
}

.circle::before {
  content: '';
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  background-color: white;
  border-radius: 50%;
  z-index: 1;
}

/* Анимация вращения */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.card-enter {
  animation: fadeIn 1s ease-out forwards;
}

@media screen and (max-width: 500px) {
  @keyframes fadeIn {
    from {
      transform: translateX(-100px);
    }
    to {
      transform: translateX(0);
    }
  }
  
  .card-enter {
    transform: translateX(-100px); 
    animation: fadeIn 1s ease-out forwards;
  }
}
