.uploader-hint {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.3px;
    color: rgba(142, 142, 147, 1);
}

.uploader-hint span {
    color: rgba(55, 112, 255, 1);
}