.header-container {
    background: transparent;
    min-height: auto;
    max-height: fit-content;
    flex: 1;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 32px 40px 32px 32px;
    background: transparent;
    height: auto;
}

.header-mobile {
    flex-direction: column;
    gap: 20px;
    padding: 32px;
}

.logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.nav-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    flex-wrap: nowrap;
    gap: 20px;
}

.nav-container-mobile {
    justify-content: center;
    flex-wrap: wrap;
}

.nav-item {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: white;
    cursor: pointer;
}

.nav-item-active {
    color: rgba(255, 255, 255, 0.6);
}