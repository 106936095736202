/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

.ant-tour {
    max-width: fit-content
}

.close-icon {
    width: 48px;
    height: 48px; 
    cursor: pointer;
}

.trash-icon {
    width: 80px; 
    height: 80px;
}

.confirmation-message {
    margin: 24px 0 0;
    font-family: 'Inter', sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    color: rgba(14, 14, 14, 1);
}

.confirmation-text {
    margin: 16px 0 0;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: rgba(14, 14, 14, 1);
}

.delete-popup {
    outline: none;
    border: none;
    box-shadow: none;
    padding: 12px 0;
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: white;
    background: rgba(55, 112, 255, 1);
    margin: 36px 0 0;
    border-radius: 999px;
    cursor: pointer;
}

.cancel-popup {
    outline: none;
    border: none;
    box-shadow: none;
    padding: 12px 0;
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: rgba(14, 14, 14, 1);
    margin: 16px 0 0;
    background: rgba(15, 25, 55, 0.1);
    backdrop-filter: blur(80px);
    border-radius: 999px;
    cursor: pointer;
}